import axios from "axios";

//Demo

export const API = "https://backend-lms.talrop.works/api/v1";

export const baseConfig = axios.create({
    baseURL: "https://backend-lms.talrop.works/api/v1",
});

// local
// export const API = "http://127.0.0.1:8009/api/v1";

// export const baseConfig = axios.create({
// 	baseURL: "http://127.0.0.1:8009/api/v1",
// });


//Server

// export const API = "https://backend.learnersuae.com/api/v1";

// export const baseConfig = axios.create({
//     baseURL: "https://backend.learnersuae.com/api/v1",
// });

// for all
export default {
    API_ENDPOINT: API,
};
